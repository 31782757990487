import React from "react";
import "./QrPage.css";

import scanQr from "./tmimages/scanqr.jpeg";
import { useNavigate } from "react-router-dom";

const QrPage = () => {
  const navToDetail = useNavigate();
  return (
    <div className="captcha-whole-div">
      <div className="qr-page-main-div">
        <p className="captcha-www-text">
          SCAN TO PAY or USE BELOW BANK DETAIL TO PAY
        </p>
        <div className="d-flex justify-content-center qrpage-scan-bank">
          <div className="qr-img-div">
            <img className="qr-img" src={scanQr}></img>
          </div>
          <div className="qrpage-bank-detail">
            <p className="qr-page-accountdetail-text">ACCOUNT DETAILS</p>
            <p className="qrpage-account-texts">Lalitha Ragul, Axis Bank</p>
            <p className="qrpage-account-texts">
              Account Number - 920010065810602
            </p>
            <p className="qrpage-account-texts">IFSC Code - UTIB0000016</p>
          </div>
        </div>
        <div className="phoneqr-img-div">
          <img className="qr-img" src={scanQr}></img>
        </div>
        {/* <p className="captcha-www-text">www.tendtomend.com</p> */}
        <span className="mt-2 payment-below-payment-text">
          Once the payment is done, click the below button to view your details.
        </span>{" "}
        <button
          className="qr-home-button mt-2"
          onClick={() => navToDetail("/details")}
        >
          Payment Done
        </button>
      </div>
    </div>
  );
};

export default QrPage;
