import React from "react";
import "./SummaryPage.css";
import { GoDotFill } from "react-icons/go";

const SummaryPage = ({ getFormData }) => {
  return (
    <div>
      <div className="summary-page-bg-div">
        <div>
          <div className="d-flex justify-content-center">
            <h2 className="summary-page-appointment-text">
              APPOINTMENT DETAILS
            </h2>
          </div>
          <div className="row summary-row-desktop">
            <div className="col col-lg-4">
              <div className="d-flex flex-column align-items-end gap-4">
                <span className="summary-page-user-label-span">
                  Client Name
                </span>
                <span className="summary-page-user-label-span">
                  Parent/Guardian
                </span>
                <span className="summary-page-user-label-span">Email Id</span>
                <span className="summary-page-user-label-span">
                  Phone Number
                </span>
                <span className="summary-page-user-label-span">Address</span>
                <span className="summary-page-user-label-span">
                  Payment Status
                </span>
                <span className="summary-page-user-label-span">
                  what are affecting you{" "}
                </span>
              </div>
            </div>
            <div className="col col-lg-8">
              <div className="d-flex flex-column">
                <span className="summary-page-user-detail-span">
                  {getFormData.name}
                </span>

                <span className="summary-page-user-detail-span">
                  {getFormData.parent}
                </span>

                <span className="summary-page-user-detail-span">
                  {getFormData.email}
                </span>
                <span className="summary-page-user-detail-span">
                  {getFormData.mobile}
                </span>

                <span className="summary-page-user-detail-span">
                  {getFormData.address}
                </span>

                <span className="summary-page-user-detail-span">paid</span>
                <span className="summary-page-user-detail-span">
                  {getFormData.checkedItems &&
                  getFormData.checkedItems.length > 0
                    ? getFormData.checkedItems.join(", ")
                    : "No items selected"}
                </span>
              </div>
            </div>
          </div>
          <div className="summary-detail-mobile">
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex flex-column summary-mobile-view-fields">
              <span className="summary-page-user-label-span">
                  Client Name
                </span>
                <span className="summary-page-user-detail-span">
                  {getFormData.name}
                </span>
              </div>
             
              <div className="d-flex flex-column summary-mobile-view-fields">
              <span className="summary-page-user-label-span">
                  Parent/Guardian
                </span>
                <span className="summary-page-user-detail-span">
                {getFormData.parent}
                </span>
              </div>
              <div className="d-flex flex-column summary-mobile-view-fields">
              <span className="summary-page-user-label-span">
                 Email Id
                </span>
                <span className="summary-page-user-detail-span">
                {getFormData.email}
                </span>
              </div>
              <div className="d-flex flex-column summary-mobile-view-fields">
              <span className="summary-page-user-label-span">
                  Phone Number
                </span>
                <span className="summary-page-user-detail-span">
                  {getFormData.mobile}
                </span>
              </div>
              <div className="d-flex flex-column summary-mobile-view-fields">
              <span className="summary-page-user-label-span">
                  Address
                </span>
                <span className="summary-page-user-detail-span">
                  {getFormData.address}
                </span>
              </div>
              <div className="d-flex flex-column summary-mobile-view-fields">
              <span className="summary-page-user-label-span">
                 Payment Status
                </span>
                <span className="summary-page-user-detail-span">
                 Paid
                </span>
              </div>
              <div className="d-flex flex-column summary-mobile-view-fields">
              <span className="summary-page-user-label-span">
              what are affecting you
                </span>
                <span className="summary-page-user-detail-span">
                {getFormData.checkedItems &&
                  getFormData.checkedItems.length > 0
                    ? getFormData.checkedItems.join(", ")
                    : "No items selected"}
                </span>
              </div>
            </div>
          </div>
          
            <div className="d-flex justify-content-center">
              <div className="summary-page-terms-div d-flex align-items-center">
                <span>
                  <GoDotFill />
                </span>
                <span>
                  You have accepted the terms and condition of the consent forms
                  and Clients rights.
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="summary-page-terms-div d-flex align-items-center">
                <span>
                  <GoDotFill />
                </span>
                <span>
                  You have accepted the terms and condition of the Fees
                  Agreement.
                </span>
              </div>
            </div>

        </div>
      </div>
    </div>
  );
};

export default SummaryPage;
