import React, { useRef, useEffect } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import "./Captcha.css"
import { useNavigate } from "react-router-dom";

export const CaptchaPage = () => {
  const captchaInputRef = useRef(null);
  const navToPayment = useNavigate();

  useEffect(() => {
    loadCaptchaEnginge(6,"#6c5138", "white");
  }, []);

  const doSubmit = () => {
    const userCaptchaValue = captchaInputRef.current.value;

    if (validateCaptcha(userCaptchaValue) === true) {
      alert("Captcha Matched");
      navToPayment("/payment");

    } else {
      alert("Captcha Does Not Match");
      captchaInputRef.current.value = "";
    }
  };

  return (
    <div className="captcha-whole-div">
     
      <div className="captcha-main-div">
      <div>
        {/* <p className="Captcha-enter-captcha-text">ENTER CAPTCHA FOR PAYMENT PAGE</p> */}
        <p className="Captcha-enter-captcha-text">ENTER CAPTCHA </p>
      </div>
        <div className="captcha-shadow-div">
      <div className="form-group">
        <div className="col mt-3">
          <LoadCanvasTemplate  reloadColor="gray"   />
        </div>

        <div className="col mt-3">
          <div>
            <input
              placeholder="Enter Captcha Value"
              name="user_captcha_input"
              type="text"
              className="captcha-input"
              ref={captchaInputRef}
            />
          </div>
        </div>


        <div className="col mt-3">
          <div>
            <button className="captcha-submit-button" onClick={doSubmit}>
              SUBMIT
            </button>
          </div>
        </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <p className="captcha-www-text">Note: Payment Pending. Please enter the captcha for the payment page.</p>
      </div>
      </div>
    </div>
  );
};
