import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./HomePage";
import UserForm from "./UserForm";
import ConsentForm from "./ConsentForm";
import FeePage from "./FeePage";
import { CaptchaPage } from "./CaptchaPage";
import QrPage from "./QrPage";
import { useState } from "react";
import SummaryPage from "./SummaryPage";

function App() {

const [getFormData, setFormData]=useState("")

const formDataFromUser= (userData)=>{
setFormData(userData)
}

console.log(getFormData)

  const apiBaseUrl =
    "https://tabsquareinfotech.com/App/Abinesh_be_work/tsit_tend2mend/public/api/";
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route
          path="/userform"
          element={<UserForm apiBaseUrl={apiBaseUrl} formDataFromUser={formDataFromUser}/>}
        ></Route>
        <Route path="/consentform" element={<ConsentForm />}></Route>
        <Route path="/aboutfee" element={<FeePage />}></Route>
        <Route path="/captcha" element={<CaptchaPage />} />
        <Route path="/payment" element={<QrPage />} />
        <Route path="/details" element={<SummaryPage getFormData={getFormData}/>}/>
      </Routes>
    </div>
  );
}

export default App;
